import { message, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";
import { useStore } from "vuex";

message.config({
  duration: 2,
  maxCount: 2,
});

const useMessage = () => {
  const store = useStore();
  const sleep = mills => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, mills);
    });
  };
  const showErr = e => {
    if (e) {
      return showTipMessage(e.message, "error");
    }
  };
  const showTipMessage = (msg, type = "success") => {
    return new Promise(resolve => {
      if (type === "success") {
        message.success(msg, () => {
          resolve();
        });
      } else if (type === "error") {
        message.error(msg, () => {
          resolve();
        });
      }
    });
  };
  const showLoading = async (msg = "加载中...") => {
    await store.dispatch("spinner/updateTipMsg", msg);
    await store.dispatch("spinner/toggleSpinner");
  };
  const closeLoading = async () => {
    await store.dispatch("spinner/toggleSpinner");
    await sleep(500);
  };
  const showConfirm = msg => {
    return new Promise(resolve => {
      Modal.confirm({
        title: "提示",
        icon: createVNode(ExclamationCircleOutlined),
        content: msg,
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  };
  return {
    sleep,
    showErr,
    showTipMessage,
    showConfirm,
    showLoading,
    closeLoading,
  };
};
export default useMessage;
