import axios from "axios";
import request from "@/utils/request";

export const listAll = () => {
  return axios.get("/parking/lot/listAll");
};


export const list = (params, page, pageSize) => {
  return axios.get("/parking/lot/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

export const save = vo => {
  return axios.post("/parking/lot/add", vo);
};

export const detail = id => {
  return axios.get(`/parking/lot/${id}/detail`);
};

export const edit = (id, vo) => {
  return axios.put(`/parking/lot/${id}/edit`, vo);
};

export const deleteParkingLot = id => {
  return axios.delete(`/parking/lot/${id}/del`);
};

export const openMerchant = data => {
  return request({
    url: "/omp/merchant/open",
    method: "POST",
    data,
  });
};
