import axios from "axios";
import { signature } from "./utils";
const service = axios.create({
  baseURL: "/couponApi", // api 的 base_url
  timeout: 15000, // request timeout
});

service.interceptors.request.use(
  config => {
    const timestamp = new Date().getTime();
    // const timestamp = "1607494588429";
    const appid = "city-api";
    const nonce = `${appid}${timestamp}`;
    const appSecret = "2ff9d4a4-1b10-44e3-aa8a-2bf383c84585";
    const sign = signature({
      appid,
      timestamp,
      nonce,
      method: config.method.toUpperCase(),
      params: config.params,
      data: config.data,
      appSecret,
    });
    config.headers = {
      "Content-type": "application/json;charset=utf-8",
      appid,
      timestamp,
      nonce,
      signature: sign,
    };
    return config;
  },
  err => Promise.reject(err),
);

// response interceptor
service.interceptors.response.use(
  async res => {
    let data = res.data;
    if (data.result === 401 || data.result === 403) {
      // removeToken();
      // 注销
      location.reload();
      return Promise.reject(null);
    } else if (data.result === 0) {
      return Promise.resolve(data);
    } else {
      return Promise.reject(new Error(data.message));
    }
  },
  async () => {
    return Promise.reject(new Error("系统异常，请联系管理员"));
  },
);

export default service;
