import store from "@/store";
import md5 from "js-md5";

export const hasPermission = p => {
  return store.state.sidebar.perms.indexOf(p) > -1;
};

export const signature = options => {
  const { appid, timestamp, nonce, params, data, appSecret } = options;
  let str = ``;
  const obj = {
    appid,
    timestamp,
    nonce,
    body: JSON.stringify(data) || "",
    ...params,
  };
  Object.keys(obj)
    .sort()
    .forEach(key => {
      str += `${obj[key]},`;
    });
  str = `${str.substring(0, str.length - 1)}${appSecret}`;
  return md5.hex(str);
};

export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
